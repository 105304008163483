import { getPrivateAPI, privateAPIv2 } from '~/api/base';
import { response } from '@api/helpers';
import { ACHRelationship } from '@models/transfers';

export default {
  fetchAccounts: (_portfolioId: string) =>
    response(privateAPIv2.get(`/ach/data-providers/q2/accounts/`))
      .then((accounts: ACHRelationship[]) => accounts.map((account) => ({ ...account, provider: 'q2' })))
      .catch(() =>
        response(privateAPIv2.get(`/ach/data-providers/banno/accounts/`)).then((accounts: ACHRelationship[]) =>
          accounts.map((account) => ({ ...account, provider: 'banno' })),
        ),
      ),
  fetchBrokerData: async () => (await getPrivateAPI({ retries: 5 })).get('platform/broker-data/'),
};
